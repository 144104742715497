/**
* @desc 函数防抖
* @param func 函数
* @param wait 延迟执行毫秒数
* @param immediate true表示立即执行，false表示非立即执行
*/

export function debounceFn (func, wait, immediate = true) {
  // 定义一个timeout计时器
  let timeout
  return function () {
    // 如果 timer 有值，代表有执行过定时器，就将定时器清除
    if (timeout) clearTimeout(timeout)
    // 默认立即执行方法，延后执行的话，会让人感觉有卡顿
    if (immediate) {
      // 定义现在是否能执行
      const now = !timeout
      if (now) func.apply(this, arguments)
      // 不论timeout有没有值，都重新给timeout新添加一个定时器
      // 等待wait时间后，将timeout设为null，代表可以继续执行次function
      timeout = setTimeout(() => {
        timeout = null
      }, wait)
    } else {
      // 如果不是立即执行此函数，则在等待wait时间后执行方法
      timeout = setTimeout(() => {
        func.apply(this, arguments)
      }, wait)
    }
  }
}

export function getQueryVariable (variable) {
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (pair[0] === variable) { return pair[1] }
  }
  return (false)
}

function getTableTr (text) {
  const list = text.split('|')
  return list.reduce((prev, cur) => {
    if (cur) {
      prev += `<td class="td">${cur}</td>`
    }
    return prev
  }, '')
}

export function getTextDom (text) {
  // 表格
  let domString = ''
  let isTableBegin = false
  if (/--\|--/g.test(text) || /-- \| --/g.test(text) || /--: \| :--/g.test(text) || /--:\|:--/g.test(text)) {
    const list = text.split('\n')
    for (let index = 0; index < list.length; index++) {
      const element = list[index]
      if (element.startsWith('|-') || element.startsWith('| -') || element.startsWith('| :-') || element.startsWith('|:-')) {
        domString += ''
      } else if (!element.startsWith('| ')) {
        if (isTableBegin) { // 表格进行中
          domString += `</table><p>${element}</p>`
        } else {
          domString += `<p>${element}</p>`
        }
        isTableBegin = false
      } else if (isTableBegin) { // 表格已经开始
        if (index < list.length - 1) {
          domString += `<tr class="tr">${getTableTr(element)}</tr>`
        } else {
          domString += `<tr class="tr">${getTableTr(element)}</tr></table>`
        }
      } else { // 表格开始
        isTableBegin = true
        domString += `<table class="table"><tr class="tr">${getTableTr(element)}</tr>`
      }
    }
  }
  return domString
}
