import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/main',
    name: 'Main',
    component: () => import(/* webpackChunkName: "about" */ '../views/main/index.vue'),
    children: [
      {
        path: 'index',
        name: 'Index',
        component: () => import(/* webpackChunkName: "about" */ '../views/index/index.vue')
      },
      {
        path: 'coin',
        name: 'Coin',
        component: () => import(/* webpackChunkName: "about" */ '../views/coin/index.vue')
      },
      {
        path: 'coinInfo',
        name: 'CoinInfo',
        component: () => import(/* webpackChunkName: "about" */ '../views/coin/info.vue')
      },
      {
        path: 'vip',
        name: 'Vip',
        component: () => import(/* webpackChunkName: "about" */ '../views/vip/index.vue')
      },
      {
        path: 'pay',
        name: 'Pay',
        component: () => import(/* webpackChunkName: "about" */ '../views/vip/pay.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/index.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import(/* webpackChunkName: "about" */ '../views/news/index.vue')
  },
  {
    path: '*',
    redirect: '/main/index'
  }
]

const router = new VueRouter({
  routes
})

export default router
